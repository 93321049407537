<template>
    <section class="content">
        <table class="table table-hover" ref="tblkelas">
        <thead>
            <tr>
				<th>NAMA MAPEL</th>
				<th>TINDAKAN</th>
            </tr>
        </thead>
        <tbody @click="handleClick"></tbody>
        </table>
    </section>
    <!-- /.content -->
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
    name: "",
    data() {
        return {
        errors: [],
        method: "",
        roles: "",
        formTitle: "Tambah",
        form: {
            kelas: "",
        },
        };
    },
    created: function () {
        this.roles = this.$route.meta.roles;
        console.log("load initial data", this.$route);
    },
    methods: {
        handleClick(e) {
            if (e.target.matches(".link-role")) {
                this.$router.push({ path: "/permit/" + e.target.dataset.id });
                return false;
            }
            if (e.target.matches(".btn-outline-info")) {
                var btnEl = $(e.target).closest("button").get(0);
                if (btnEl.dataset.action == "detail") {
                    var mapel_id = btnEl.dataset.id;
    				var kelas_id = this.$route.params.id;
                    var program_id = this.$route.params.program_id;
                    this.$router.push('/rekap-ujian/siswa/ujianlist/'+kelas_id+'/'+mapel_id+'/'+program_id);
                }
                return false;
            }
            if (e.target.matches(".btn-outline-success")) {
                var btnEl = $(e.target).closest("button").get(0);
                if (btnEl.dataset.action == "detail") {
                    var mapel_id = btnEl.dataset.id;
                    var kelas_id = this.$route.params.id;
                    var program_id = this.$route.params.program_id;
                    this.$router.push('/rekap-latihan/siswa/'+kelas_id+'/'+mapel_id+'/'+program_id);
                }

                if(btnEl.dataset.action == "ujian-kecermatan") {
                    var mapel_id = btnEl.dataset.id;
                    var kelas_id = this.$route.params.id;
                    var program_id = this.$route.params.program_id;
                    this.$router.push('/rekap-ujian/siswa/ujianlist/'+kelas_id+'/34/'+program_id);

                }
                return false;
            }
        },
    },
    mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tblkelas, {
        title: "",
        roles: ["read"],
        ajax: "/masterdata/mapel/"+self.$route.params.program_id,
        columns: [
            { data: "name" },
            { data: "action",
            render: function (data, type, row, meta) {
                if (row.name.indexOf("POLRI") != -1 || row.name.indexOf("SKD") != -1 || row.name.indexOf("TPA") != -1) {
                    return `<div class="btn-group">
                    <button type="button" class="btn btn-sm btn-outline-info" data-action="detail" data-id="` + row.id +`"><i class="fas fa-eye"></i> Detail Rekap Tryout</button>
                    <button type="button" class="btn btn-sm btn-outline-success disabled" data-action="detail" data-id="` + row.id +`"><i class="fas fa-eye"></i> Detail Rekap Latian</button>
                    </div>`    
                } else if (row.id == 34) {
                    return '<button class="btn btn-sm btn-outline-success" data-action="ujian-kecermatan" type="button"><i class="fas fa-eye"></i> Detail Ujian</button>'
                } else {
                    return `<div class="btn-group">
                    <button type="button" class="btn btn-sm btn-outline-info" disabled data-action="detail" data-id="` + row.id +`"><i class="fas fa-eye"></i> Detail Rekap Tryout</button>
                    <button type="button" class="btn btn-sm btn-outline-success" data-action="detail" data-id="` + row.id +`"><i class="fas fa-eye"></i> Detail Rekap Latian</button>
                    </div>`
                }
            } },
        ],
        // paramData: function(d) {
        // if (self.$route.params.id) {
        //     d.program_id = self.$route.params.id;
        // } else {
        //     d.program_id = 0;
        // }
        // },
        // filterBy: [0],
        filter: false,
        rowCallback: function (row, data) {
        // $('td:eq(2)', row).html('<a type="button" class="btn btn-primary" data-id="'+data.id+'" data-branch="'+data.branch_id+'">Data Siswa</a>');
        },
    });
},
};
</script>
